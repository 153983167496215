import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import theme from "!!raw-loader!./theme.ts";
import { Link } from "docz";
import { Code } from "gatsby-theme-docz/src/components/Code";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "utils"
    }}>{`Utils`}</h1>
    <p>{`A collections of utils and types, used across Styled packages.`}</p>
    <h2 {...{
      "id": "styles-generator"
    }}>{`Styles Generator`}</h2>
    <p>{`Function to map a component's props interface to a valid styles object using a custom mapper function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { stylesGenerator } from "@madebywild/styled-utils";

const props = {
  // The theme is usually provided by the ThemeProvider
  theme: {
    screens: {
      sm: 640,
      md: 768,
    },
  },
  gap: {
    initial: [2, 4],
    sm: [1, 3],
    md: [4, 5],
  },
  columns: {
    initial: 2,
    sm: 3,
    md: 4,
  },
};

const styles = stylesGenerator(componentStylesGenerator)(props);
`}</code></pre>
    <h3 {...{
      "id": "output"
    }}>{`Output:`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "gap": "2px 4px",
  "gridColumns": 2,
  "@media only screen and (min-width: 640px)": {
    "gap": "1px 3px",
    "gridColumns": 3
  },
  "@media only screen and (min-width: 768px)": {
    "gap": "4px 5px",
    "gridColumns": 4
  }
}
`}</code></pre>
    <h2 {...{
      "id": "responsive-props"
    }}>{`Responsive Props`}</h2>
    <div>
  Typescript utilities to convert a component's prop interface to a responsive interface and vice versa. For more
  details, visit the <Link to="/styled/responsive" mdxType="Link">responsive guide</Link>.
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import type { ResponsifyProps, UnresponsifyProps } from "@madebywild/styled-utils";

interface Theme {
  screens: Record<"sm" | "lg", unknown>;
}

interface Props {
  foo: string;
  bar: number;
}
`}</code></pre>
    <h3 {...{
      "id": "responsifyprops"
    }}>{`ResponsifyProps`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type ResponsiveProps = ResponsifyProps<Props>;

interface ResponsiveProps {
  foo: string | { sm?: string; lg?: string };
  bar: number | { sm?: number; lg?: number };
}
`}</code></pre>
    <h3 {...{
      "id": "unresponsifyprops"
    }}>{`UnresponsifyProps`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type UnresponsiveProps = UnresponsifyProps<ResponsiveProps>;

interface UnresponsiveProps {
  foo: string;
  bar: number;
}
`}</code></pre>
    <h2 {...{
      "id": "theme"
    }}>{`Theme`}</h2>
    <div>
  Default theme object and interface to use as the starting point when creating a theme for a new project. Both the
  theme object and the interface can be extended to meet your project's requirements. Visit the{" "}
  <Link to="/styled/getting-started#theming" mdxType="Link">getting started page</Link> to learn how to add and extend the theme in
  your project.
    </div>
    <h3 {...{
      "id": "default-theme"
    }}>{`Default theme`}</h3>
    <Code className="ts" mdxType="Code">{theme}</Code>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      